import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog as MatDialog } from "@angular/material/dialog";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { TicketDataService } from "../ticket-data.service";
import { Ticket } from "../ticketData";
import { BrandDataService } from "../brand-data.service";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-belegheft",
    templateUrl: "./belegheft.component.html",
    styleUrls: ["./belegheft.component.scss"],
    standalone: false
})
export class BelegheftComponent implements OnInit {
  @Input() lang: string;
  titles = [
    { viewValue: "Dr." },
    { viewValue: "Prof." },
    { viewValue: "Prof. Dr." },
    { viewValue: "Prof. Prof." },
  ];

  year: number = new Date().getFullYear();
  // formGroup with formControls
  ticketDataForm = new UntypedFormGroup({
    anredeTypeControl: new UntypedFormControl("", Validators.required),
    titleTypeControl: new UntypedFormControl(""),
    fullNameFormControl: new UntypedFormControl("", Validators.required),
    emailFormControl: new UntypedFormControl("", [
      Validators.required,
      Validators.email,
    ]),
    jobFormControl: new UntypedFormControl("", Validators.required),
    firmaFormControl: new UntypedFormControl("", Validators.required),
    detailsFormControl: new UntypedFormControl("", Validators.required),
    strasseFormControl: new UntypedFormControl("", Validators.required),
    plzFormControl: new UntypedFormControl("", Validators.required),
    stadtFormControl: new UntypedFormControl("", Validators.required),
    landFormControl: new UntypedFormControl("", Validators.required),
    brandFormControl: new UntypedFormControl("", Validators.required),
    ausgabeFormControl: new UntypedFormControl("", Validators.required),
    mengeTypeControl: new UntypedFormControl("", Validators.required),
    angebotFormControl: new UntypedFormControl(""),
    dataAgreedFormControl: new UntypedFormControl("", Validators.required),
  });
  param: {
    lang: string;
    brand: "vcg";
    email: string;
    ausgabe: string;
    anrede: string;
    name: string;
  };
  title = "Customer Support Center";
  toolbar_img = "https://cdn4.vogel.de/logos/1009/logo.svg";
  ticketDataValidation: boolean = false;
  dataPolicyValidation: boolean = true;
  brands = [];
  showProgress = false;
  formSelectedBrand;
  showZUA = true;
  ticketData: Ticket;
  ticketId;
  constructor(
    private dataService: TicketDataService,
    private brandService: BrandDataService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {}
  ngOnInit() {
    this.lang = this.activatedRoute.snapshot.params["lang"];
    if (
      this.lang != "en" &&
      this.lang != "fr" &&
      this.lang != "de" &&
      this.lang != undefined
    ) {
      this.translate.setDefaultLang("en");
      this.lang = "en";
      this.removeValidators();
    }
    if (this.lang == undefined) {
      const browserLang = this.translate.getBrowserLang();
      this.lang = browserLang;
      this.translate.use(browserLang.match(/en|fr|de/) ? browserLang : "en");
    } else {
      this.translate.use(this.lang);
    }
    if (this.lang === "en" || this.lang === "fr") {
      this.removeValidators();
    } else if (this.lang === "de") {
      this.ticketDataForm
        .get("detailsFormControl")
        .removeValidators(Validators.required);
    }

    this.brandService.getAllBrands().subscribe((response) => {
      if (response["status"] == "success") {
        response["data"].forEach((item) => {
          if (item.hasPrint === 1) {
            this.brands.push({
              value: item.brandShortcut,
              viewValue: item.brandFullName,
              brandID: item.brandID
            });
          }
        });
      }
    });

    this.param = {
      lang: this.activatedRoute.snapshot.params["lang"],
      brand: this.activatedRoute.snapshot.params["brand"],
      email: this.activatedRoute.snapshot.params["email"],
      ausgabe: this.activatedRoute.snapshot.params["ausgabe"],
      anrede: this.activatedRoute.snapshot.params["anrede"],
      name: this.activatedRoute.snapshot.params["name"],
    };
    this.ticketDataForm.get("ausgabeFormControl").setValue(this.param.ausgabe);
    this.ticketDataForm.get("brandFormControl").setValue(parseInt(this.param.brand));
    this.ticketDataForm.get("emailFormControl").setValue(this.param.email);
    this.ticketDataForm.get("anredeTypeControl").setValue(this.param.anrede);
    this.ticketDataForm.get("fullNameFormControl").setValue(this.param.name);
  }

  setLanguage(item: string) {
    this.lang = item;
    if (item === "en" || item === "fr") {
      this.removeValidators();
    } else if (item === "de") {
      this.ticketDataForm
        .get("detailsFormControl")
        .removeValidators(Validators.required);
    }
  }

  // if form submit
  submitTicketData(ticketData: any): void {
    const angebot = this.ticketDataForm.get("mengeTypeControl").value;
    if (angebot === "angebot") {
      this.ticketDataForm
        .get("angebotFormControl")
        .addValidators(Validators.required);
    } else {
      this.ticketDataForm
        .get("angebotFormControl")
        .removeValidators(Validators.required);
    }

    if (this.ticketDataForm.valid) {
      window.scroll(0, 0);

      const selectedBrandId = this.ticketDataForm.get("brandFormControl").value;
      this.brands.forEach(brand => {
        if (brand.brandID === selectedBrandId ) {
          ticketData = [
            {
              anrede: this.ticketDataForm.get("anredeTypeControl").value,
              title: this.ticketDataForm.get("titleTypeControl").value,
              fullName: this.ticketDataForm.get("fullNameFormControl").value,
              email: this.ticketDataForm.get("emailFormControl").value,
              job: this.ticketDataForm.get("jobFormControl").value,
              firma: this.ticketDataForm.get("firmaFormControl").value,
              straße: this.ticketDataForm.get("strasseFormControl").value,
              plz: this.ticketDataForm.get("plzFormControl").value,
              stadt: this.ticketDataForm.get("stadtFormControl").value,
              land: this.ticketDataForm.get("landFormControl").value,
              details: this.ticketDataForm.get("detailsFormControl").value,
              brand: brand.viewValue,
              ausgabe: this.ticketDataForm.get("ausgabeFormControl").value,
              menge: this.ticketDataForm.get("mengeTypeControl").value,
              angebot: this.ticketDataForm.get("angebotFormControl").value,
              dataAgreement: this.ticketDataForm.get("dataAgreedFormControl").value,
              requestType: "belegheft",
              lang: this.lang,
            },
          ];
        }
      })
      this.showProgress = true;
      this.dataService.addTicketData(ticketData).subscribe((res) => {
        setTimeout(() => {
          if (res["status"] == "success") {
            this.ticketId = res["message"];
            this.ticketDataValidation = true;
            this.ticketDataForm.reset();
            this.showProgress = false;
          }
        }, 1100);
      });
    } else {
      if (this.ticketDataForm.get("dataAgreedFormControl").value == false) {
        this.dataPolicyValidation = false;
      }
      if (this.ticketDataValidation == false) {
        this._snackBar.openFromComponent(SnackBarInfoBelegheft, {
          duration: 2000,
        });
      }
    }
  }
  removeValidators() {
    this.ticketDataForm
      .get("strasseFormControl")
      .removeValidators(Validators.required);
    this.ticketDataForm
      .get("plzFormControl")
      .removeValidators(Validators.required);
    this.ticketDataForm
      .get("stadtFormControl")
      .removeValidators(Validators.required);
    this.ticketDataForm
      .get("landFormControl")
      .removeValidators(Validators.required);
  }viewValue
  // if privacy policy checkbox state change
  dataCheckBoxChange() {
    if (this.ticketDataForm.get("dataAgreedFormControl").value == false) {
      this.dataPolicyValidation = false;
    } else {
      this.dataPolicyValidation = true;
      this._snackBar.dismiss();
    }
  }

  openDialog() {
    //const dialogRef = this.dialog.open(DialogDataPolicy);
    this.dialog.open(DialogDataPolicyBelegheft);
    /*dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });*/
  }
}
// data policy dialog
@Component({
    selector: "data-policy-dialog",
    templateUrl: "./../data-policy-dialog.html",
    standalone: false
})
export class DialogDataPolicyBelegheft {}
// snack bar
@Component({
    selector: "snack-bar-info",
    templateUrl: "./../snack-bar-info.html",
    standalone: false
})
export class SnackBarInfoBelegheft {}
