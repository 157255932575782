<mat-progress-bar *ngIf="showProgress == true" mode="indeterminate"></mat-progress-bar>

<app-toolbar url="belegheft" (newItemEvent)="setLanguage($event)"></app-toolbar>

<div class="logo-align">
    <a class="logo-element" href="https://vogel.de/" target="_blank" rel="noreferrer"><img class="logo" alt="Logo" src="https://cdn4.vogel.de/logos/1009/logo.svg" /></a>
</div>

<!-- content -->
<div *ngIf="ticketDataValidation == false" class="content" role="main">

    <div class="mobile-content">
        <p>{{ 'FORM.PRINTEDCOPY' | translate }}</p>
    </div>

    <!-- form -->
    <form [formGroup]="ticketDataForm" (ngSubmit)="submitTicketData($event)" class="form">

        <div>
            <!-- anrede input field -->
            <mat-form-field appearance="fill" style="margin-top: 20px;">
                <mat-label>{{ 'FORM.SALUTATION' | translate }}</mat-label>
                <mat-select formControlName="anredeTypeControl">
                    <mat-option class="mat-select-pinny" value="Frau">Frau</mat-option>
                    <mat-option class="mat-select-pinny" value="Herr">Herr</mat-option>
                    <mat-option class="mat-select-pinny" value="Divers">Divers</mat-option>
                </mat-select>
                <mat-hint align="start">{{ 'FORM.SALUTATIONHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.anredeTypeControl.errors?.required">
                    {{ 'FORM.SALUTATIONHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- title select -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.TITLE' | translate }}</mat-label>
                <mat-select formControlName="titleTypeControl">
                    <mat-option class="mat-select-pinny" *ngFor="let title of titles" [value]="title.viewValue">
                        {{ title.viewValue }}
                    </mat-option>
                </mat-select>
                <mat-hint align="start">{{ 'FORM.TITLEHINT' | translate }}</mat-hint>
            </mat-form-field>

            <!-- fullname input field -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.FULLNAME' | translate }}</mat-label>
                <input maxlength="64" required matInput formControlName="fullNameFormControl">
                <mat-hint align="start">{{ 'FORM.EXAMPLE' | translate }}: Max Muster</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.fullNameFormControl.errors?.required">
                    {{ 'FORM.REQUIREDFULLNAME' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- email input field -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.EMAIL' | translate }}</mat-label>
                <input maxlength="64" required matInput
                    formControlName="emailFormControl">
                <mat-hint align="start">{{ 'FORM.EXAMPLE' | translate }}: max.mustermann&#64;example.com</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.emailFormControl.errors?.email">
                    {{ 'FORM.REQUIREDVALIDEMAIL' | translate }}
                </mat-error>
                <mat-error *ngIf="ticketDataForm.controls.emailFormControl.errors?.required">
                    {{ 'FORM.REQUIREDEMAIL' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- job input field -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.JOBTITLE' | translate }}</mat-label>
                <input maxlength="64" required matInput formControlName="jobFormControl">
                <mat-hint align="start">{{ 'FORM.JOBTITLEHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.jobFormControl.errors?.required">
                    {{ 'FORM.JOBTITLEHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- firma input field -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.COMPANY' | translate }}</mat-label>
                <input maxlength="64" required matInput formControlName="firmaFormControl">
                <mat-hint align="start">{{ 'FORM.COMPANYHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.firmaFormControl.errors?.required">
                    {{ 'FORM.COMPANYHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- address input field-->
            <mat-form-field *ngIf="this.lang === 'en' || this.lang === 'fr'" appearance="fill">
                <mat-label>{{ 'FORM.ADDRESS' | translate }}</mat-label>
                <textarea cdkTextareaAutosize cdkAutosizeMinRows="6" required matInput maxlength="32765"
                 formControlName="detailsFormControl"></textarea>
                <mat-hint align="start">{{ 'FORM.ADDRESSHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.detailsFormControl.errors?.required">
                    {{ 'FORM.ADDRESSHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- straße input field -->
            <mat-form-field *ngIf="this.lang === 'de'" appearance="fill">
                <mat-label>{{ 'FORM.STREET' | translate }}</mat-label>
                <input maxlength="64" required matInput formControlName="strasseFormControl">
                <mat-hint align="start">{{ 'FORM.STREETHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.strasseFormControl.errors?.required">
                    {{ 'FORM.STREETHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- plz input field -->
            <mat-form-field *ngIf="this.lang === 'de'" appearance="fill">
                <mat-label>{{ 'FORM.PLZ' | translate }}</mat-label>
                <input maxlength="64" required matInput formControlName="plzFormControl">
                <mat-hint align="start">{{ 'FORM.PLZHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.plzFormControl.errors?.required">
                    {{ 'FORM.PLZHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- stadt input field -->
            <mat-form-field *ngIf="this.lang === 'de'" appearance="fill">
                <mat-label>{{ 'FORM.CITY' | translate }}</mat-label>
                <input maxlength="64" required matInput formControlName="stadtFormControl">
                <mat-hint align="start">{{ 'FORM.CITYHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.stadtFormControl.errors?.required">
                    {{ 'FORM.CITYHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- land input field -->
            <mat-form-field *ngIf="this.lang === 'de'" appearance="fill">
                <mat-label>{{ 'FORM.COUNTRY' | translate }}</mat-label>
                <input maxlength="64" required matInput formControlName="landFormControl">
                <mat-hint align="start">{{ 'FORM.COUNTRYHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.landFormControl.errors?.required">
                    {{ 'FORM.COUNTRYHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- brand input field -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.BRAND' | translate }}</mat-label>
                <mat-select formControlName="brandFormControl">
                    <mat-option *ngFor="let brand of brands" [value]="brand.brandID">
                        {{ brand.viewValue }}
                    </mat-option>
                </mat-select>
                <mat-hint align="start">{{ 'FORM.BRANDHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.brandFormControl.errors?.required">
                    {{ 'FORM.BRANDHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- ausgabe input field -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.ISSUE' | translate }}</mat-label>
                <input maxlength="64" required matInput formControlName="ausgabeFormControl">
                <mat-hint align="start">{{ 'FORM.ISSUEHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.ausgabeFormControl.errors?.required">
                    {{ 'FORM.ISSUEHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- menge input field -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.AMOUNT' | translate }}</mat-label>
                <mat-select #mengeTypeControl formControlName="mengeTypeControl" required>
                    <mat-option value="1">1</mat-option>
                    <mat-option value="2">2</mat-option>
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="angebot">{{ 'FORM.INDIVUAL_OFFER' | translate }}</mat-option>
                </mat-select>
                <mat-hint align="start">{{ 'FORM.AMOUNTHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.mengeTypeControl.errors?.required">
                    {{ 'FORM.AMOUNTHINT' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="mengeTypeControl.value === 'angebot'">
                <mat-label>{{ 'FORM.AMOUNT_BILLABLE' | translate }}</mat-label>
                <input matInput onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" formControlName="angebotFormControl"/>
                <mat-hint align="start">{{ 'FORM.AMOUNT_BILLABLE_HINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.angebotFormControl.errors?.required">
                    {{ 'FORM.AMOUNT_BILLABLE_HINT' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div>
        <!-- agree with data policy checkbox -->
            <mat-checkbox (change)="dataCheckBoxChange()" formControlName="dataAgreedFormControl" class="data-checkbox"
                required>
                <p class="data-checkbox-text">
                    {{ 'FORM.AGREED' | translate }}{{ 'FORM.DATAPROTECTION_1' | translate }} <a
                    href="https://legal.vogel.de/legal-cockpit/vcg-vogel-communications-group/dse/vcg-datenschutzerklaerung-zentral/'" target="_blank">{{
                    'FORM.DATAPROTECTION_2' |
                    translate }}</a>
                {{ 'FORM.DATAPROTECTION_3' | translate }} *
                </p>
            </mat-checkbox>
            <mat-error *ngIf="dataPolicyValidation == false">
                {{ 'FORM.REQUIREDDATAAGREEMENT' | translate }}
            </mat-error><br>

        <!-- button 'send' at the bottom of the form -->
        <div class="button-position">
            <button>{{ 'FORM.SEND' | translate }}</button>
        </div>

        <p style="padding-top: 14px;font-size: 14px">* {{ 'FORM.REQUIREDFIELDS' | translate }}</p>
    </div>
    </form>
    <!-- footer -->
    <div [ngStyle]="{'position' : 'relative' }" class="footer" role="footer">
        <a href="https://www.vogel.de/impressum/" target="_blank" rel="noreferrer">{{ 'FOOTER.IMPRINT' | translate }}</a> | <a href="https://privacy.vogel.de/datenschutz.html" target="_blank" rel="noreferrer">{{ 'DATAPOLICY.TITLE' | translate }}</a><br>
        Copyright {{ year }} <a href="https://www.vogel.de/" target="_blank" rel="noreferrer">Vogel Communications Group</a> GmbH &amp; Co. KG        
    </div>
</div>

<!-- text if ticket successful submitted -->
<div class="content ticket-submitted-text" role="main" *ngIf="ticketDataValidation == true">
    <h1 style="color: #2289f7">{{ 'FORM.WE_GOT_IT' | translate }}</h1>
    <br>
    <h3>{{ 'FORM.SERVICENUMBER' | translate }} <b>{{ ticketId }}</b>.<br><br>
        <p>{{ 'FORM.WE_CHECK_IT_WITHIN_TWO_DAYS' | translate }} </p><br>
        <p>{{ 'FORM.BESTREGARDS' | translate }},</p>
        <p>{{ 'FORM.SUPPORTTEAM' | translate}}</p>
    </h3>
    <!-- footer -->
    <div [ngStyle]="{'position' : 'absolute' }" class="footer" role="footer">
        <a href="https://www.vogel.de/impressum/" target="_blank" rel="noreferrer">{{ 'FOOTER.IMPRINT' | translate }}</a> | <a href="https://privacy.vogel.de/datenschutz.html" target="_blank" rel="noreferrer">{{ 'DATAPOLICY.TITLE' | translate }}</a><br>
        Copyright {{ year }} <a href="https://www.vogel.de/" target="_blank" rel="noreferrer">Vogel Communications Group</a> GmbH &amp; Co. KG        
    </div>
</div>