import { Component } from '@angular/core';

@Component({
    selector: 'vogel-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})

export class AppComponent {

  constructor() { }
}