import { Component, Input, Output, EventEmitter , OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    standalone: false
})
export class ToolbarComponent implements OnInit {
  @Input() url: string;
  @Output() newItemEvent = new EventEmitter<string>();
  param;

  constructor(
    public translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.param = {
      lang: this.activatedRoute.snapshot.params['lang'],
      brand: this.activatedRoute.snapshot.params['brand'],
      requestType: this.activatedRoute.snapshot.params['requestType']
    };

    if (this.param.lang != 'en' && this.param.lang != 'fr' && this.param.lang != 'de' && this.param.lang != undefined) {
      this.translate.setDefaultLang('en');
      this.param.lang = 'en';
    };
    if (this.param.lang == undefined) {
      const browserLang = this.translate.getBrowserLang();
      this.param.lang = browserLang;
      this.translate.use(browserLang.match(/en|fr|de/) ? browserLang : 'en');
    } else {
      this.translate.use(this.param.lang);
    }
  }

  // url navigation
  goto(lang: string): void{
    let _url = lang;
    this.newItemEvent.emit(lang);
    if (this.url === 'content') {
      if ( this.param.brand !== undefined ) {
        _url = _url + '/' + this.param.brand;
      };
      if ( this.param.requestType !== undefined && this.param.brand !== undefined ) {
        _url = _url + '/' + this.param.requestType;
      }
      this.router.navigate([_url], { queryParams: { info: this.param.info}});
    } else {
      this.router.navigate([this.url + '/' + lang])
    }
  }

}
